class ExpandableText {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.text = this.root.querySelector('.expandable-text__text');
        this.toggle = this.root.querySelector('.expandable-text__toggle');

        this.active = false;

        return true;
    }

    init() {
        this.setToggle();
    }

    setToggle() {
        if (!this.text || !this.toggle) return;
        this.toggle.addEventListener('click', this.onToggle.bind(this));
    }

    onToggle(event) {
        const { currentTarget } = event;

        this.active = !this.active;
        this.root.classList.toggle('active');

        const btnText = currentTarget.getAttribute('data-text');
        const btnToggleText = currentTarget.getAttribute('data-toggle-text');

        if (this.active) {
            currentTarget.innerHTML = btnToggleText;
        } else {
            currentTarget.innerHTML = btnText;
        }
    }
}

const initExpandableText = () => {
    const sections = document.querySelectorAll('.expandable-text');
    if (!sections) return;

    sections.forEach(item => new ExpandableText(item));
};

initExpandableText();
